import type { Key } from 'react';
import { Table as AntdTable } from 'antd';
import type { TableProps as AntdTableProps } from 'antd';
import classNames from 'classnames';

export interface TableRecord {
  key?: Key;
}

interface TableProps<T> extends AntdTableProps<T>, TableRecord {
  scrollable?: boolean;
}

export function Table<T>({
  key,
  scrollable,
  ...props
}: Readonly<TableProps<T>>) {
  return (
    <AntdTable
      scroll={scrollable ? { x: 'max-content' } : {}}
      tableLayout="auto"
      rowClassName={classNames('font-base-normal', {
        'cursor--pointer': props.onRow
      })}
      rowHoverable={true}
      size={'small'}
      key={key}
      {...props}
    />
  );
}
