import { get } from 'lodash';
import { replaceVariables } from '../../../i18n/Interpolation';
import { getText, type LocalizedText } from '../../../i18n/LocalizedText';
import type Reference from '../../../references/Reference';
import { useReferences } from '../../../references/useReferences';
import { getCredits } from '../../../types/canonical/Credits';
import type Data from '../../../types/canonical/Data';
import type Study from '../../../types/canonical/Study';
import { ProductTypeEnum, productTypeMatches } from '../../../types/Product';
import { type WidgetType } from '../../types/WidgetType';
import {
  translateWidgetFields,
  widgetFieldHasFormat
} from '../../WidgetField/utils/WidgetFieldUtils';
import type WidgetField from '../../WidgetField/WidgetField';
import { FieldSection } from './FieldSection';

interface ValuesItemProps {
  field: WidgetField;
  type: WidgetType;
  data: Data;
  hideHeader?: boolean;
  isTitle: boolean;
}

function isEmpty(value: string | number | undefined | null) {
  return value === null || value === undefined || value === '';
}

export function ValuesItem({
  field,
  type,
  data,
  hideHeader,
  isTitle
}: Readonly<ValuesItemProps>) {
  const { references } = useReferences(field.referenceType, data.year.id);
  const value = getWidgetFieldValue(field, data, references);

  return (
    <FieldSection
      field={field}
      type={type}
      isEmpty={isEmpty(value)}
      hideHeader={hideHeader}
      isTitle={isTitle}
    >
      {() => <div>{value}</div>}
    </FieldSection>
  );
}

function getWidgetFieldValue(
  field: WidgetField,
  data: Data,
  references: Reference[]
) {
  const { name } = field;
  if (!name) {
    return '';
  }

  if (name === 'code') {
    return data.code;
  } else if (name === 'name') {
    return getText(data.names);
  } else if (name === 'credits') {
    return `${getCredits(data.credits)}`;
  } else if (name === 'year') {
    return getName(data.year, field);
  } else if (name === 'faculty') {
    return getName(data.faculty, field);
  } else if (name === 'organisation') {
    return getName(data.organisation, field);
  } else if (
    name === 'specification' &&
    productTypeMatches(data.type, ProductTypeEnum.STUDY)
  ) {
    const study = data as Study;
    return getName(study.specification, field);
  }

  const value = get(data, name);
  if (value !== undefined) {
    return translateWidgetFields([value], references);
  }
  if (widgetFieldHasFormat(field) && data.code) {
    const context = { code: data.code, names: data.names };
    return getName(context, field);
  }

  return '';
}

interface NameContext {
  code: string;
  names?: LocalizedText[];
}

function getName(context: NameContext | undefined, field: WidgetField) {
  if (!context) {
    return '';
  }

  const name = getText(context.names, context.code) || '';
  if (!field.format) {
    return name;
  }

  return replaceVariables(field.format, {
    code: context.code,
    name
  });
}
