import { useAuthentication } from '../../authentication/useAuthentication';
import { type Product } from '../../types/Product';
import { type WidgetType } from '../types/WidgetType';
import type WidgetField from '../WidgetField/WidgetField';
import { AssessmentsItem } from './components/AssessmentsItem';
import { CustomItem } from './components/CustomItem';
import { DescriptionItem } from './components/DescriptionItem';
import { GroupsItem } from './components/GroupsItem';
import { LicensesItem } from './components/LicensesItem';
import { LinksItem } from './components/LinksItem';
import { MethodsItem } from './components/MethodsItem';
import { ObjectivesItem } from './components/ObjectivesItem';
import { PeriodsItem } from './components/PeriodsItem';
import { PeriodsValueItem } from './components/PeriodsValueItem';
import { RelationsItem } from './components/RelationsItem';
import { RulesItem } from './components/RulesItem';
import { SubjectsItem } from './components/SubjectsItem';
import { UrlItem } from './components/UrlItem';
import { ValuesItem } from './components/ValuesItem';

interface WidgetItemProps {
  field: WidgetField;
  type: WidgetType;
  product: Product;
  expanded: boolean;
  expandable: boolean;
  hideHeader: boolean;
  isTitle: boolean;
}

export function WidgetItem({
  field,
  type,
  product,
  expanded,
  expandable,
  hideHeader,
  isTitle
}: Readonly<WidgetItemProps>) {
  const auth = useAuthentication();
  const isAdmin = auth?.currentUser?.roles.includes('ADMIN');

  if (field.admin && !isAdmin) {
    return <></>;
  }

  const defaultProps = { field, type, hideHeader, isTitle };

  switch (field.type) {
    case 'ADDITIONAL':
      return (
        <CustomItem
          {...defaultProps}
          values={product.data.additional?.values}
          year={product.data.year.id}
        />
      );
    case 'ASSESSMENTS':
      return (
        <AssessmentsItem
          {...defaultProps}
          appraisals={product.data.assessments || []}
          year={product.data.year.id}
        />
      );
    case 'CUSTOM':
      return (
        <CustomItem
          {...defaultProps}
          values={product.data.values}
          year={product.data.year.id}
        />
      );
    case 'DESCRIPTION':
      return (
        <DescriptionItem
          {...defaultProps}
          product={product}
          expanded={expanded}
          expandable={expandable}
        />
      );
    case 'GROUPS':
      return (
        <GroupsItem
          {...defaultProps}
          product={product}
        />
      );
    case 'LICENSES':
      return (
        <LicensesItem
          {...defaultProps}
          licenses={product.data.licenses || []}
          year={product.data.year.id}
        />
      );
    case 'LINKS':
      return (
        <LinksItem
          {...defaultProps}
          product={product}
        />
      );
    case 'METHODS':
      return (
        <MethodsItem
          {...defaultProps}
          methods={product.data.methods || []}
          year={product.data.year.id}
        />
      );
    case 'OBJECTIVES':
      return (
        <ObjectivesItem
          {...defaultProps}
          objectives={product.data.objectives || []}
        />
      );
    case 'PERIODS':
      return (
        <PeriodsItem
          {...defaultProps}
          offerings={product.data.offerings}
          year={product.data.year.id}
        />
      );
    case 'PERIODS_VALUE':
      return (
        <PeriodsValueItem
          {...defaultProps}
          offerings={product.data.offerings}
          year={product.data.year.id}
        />
      );
    case 'RELATION':
      return (
        <RelationsItem
          {...defaultProps}
          relations={product.data?.relations}
        />
      );
    case 'RULES':
      return (
        <RulesItem
          field={field}
          product={product}
        />
      );
    case 'SUBJECTS':
      return (
        <SubjectsItem
          {...defaultProps}
          data={product.data}
        />
      );
    case 'URL':
      return (
        <UrlItem
          field={field}
          data={product.data}
        />
      );
    case 'VALUE':
      return (
        <ValuesItem
          {...defaultProps}
          data={product.data}
        />
      );
    default:
      return <div></div>;
  }
}
