import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from '../../../components';
import { getDefaultCode } from '../../../types/canonical/Data';
import type Group from '../../../types/canonical/Group';
import type WidgetField from '../../../widgets/WidgetField/WidgetField';
import { setGroup } from '../../GroupStructure/structureSlice';
import {
  type StructureStoreDispatch,
  type StructureStoreReturnType
} from '../../GroupStructure/structureStore';
import useGroupModules from '../hooks/useGroupModules.hook';
import GroupModulesOverviewTable from './GroupModulesOverviewTable';

type GroupModulesTableProps = {
  group: Group;
  year: string;
  fields: WidgetField[];
  isRoot?: boolean;
};

export function GroupModulesOverview({
  group,
  year,
  fields,
  isRoot = false
}: Readonly<GroupModulesTableProps>) {
  const ensuredId = getDefaultCode(group);
  const dispatch: StructureStoreDispatch = useDispatch();

  const groupSettings = useSelector(
    (state: StructureStoreReturnType) => state.groups[ensuredId]
  );

  const state = useGroupModules({
    group: group,
    options: {
      enabled: groupSettings.isFetched || groupSettings.isExpanded || isRoot
    }
  });

  // set "isFetched" to group in store, this allows us to keep the useQuery state enabled.
  useEffect(() => {
    if (!state.isFetched) return;
    dispatch(setGroup({ code: ensuredId, settings: { isFetched: true } }));
  }, [dispatch, ensuredId, state.isFetched]);

  return (
    <Loader state={state}>
      {(moduleRows) => (
        <GroupModulesOverviewTable
          fields={fields}
          moduleRows={moduleRows}
          year={year}
        />
      )}
    </Loader>
  );
}
