import { addSuccess } from '@42.nl/react-flash-messages';
import { type Url, urlBuilder, useQueryParams } from '@42.nl/react-url/lib';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { AppFrame, Loader } from '../../components';
import { AdminButton } from '../../components/AdminButton/AdminButton';
import { toQueryParams } from '../../components/AppFrame/components/TopBar/components/MainTabs/MainTabs';
import { PrintButton } from '../../components/Print/PrintButton';
import { structureStore } from '../../groups/GroupStructure/structureStore';
import ProductHeader from '../../product/ProductHeader/ProductHeader';
import { ProductPrint } from '../../product/ProductPrint';
import { useActiveMainTab } from '../../tabs/context/hooks/useActiveMainTab';
import { useSubTabs } from '../../tabs/context/hooks/useSubTabs';
import SubTabsNav from '../../tabs/SubTabsNav';
import { getActiveSubTab } from '../../tabs/utils/subTabUtils';
import type Qualification from '../../types/canonical/Qualification';
import {
  type ProductDetailPathParams,
  type ProductDetailQueryParams,
  useProductQuery
} from '../../types/Product';
import SimpleQualification from '../../types/SimpleQualification';
import { WidgetButtons } from '../../widgets/WidgetDetail/WidgetButtons';
import { WidgetDetail } from '../../widgets/WidgetDetail/WidgetDetail';
import ProductDetailYearDropdown from '../../years/components/YearDropdown/ProductDetailYearDropdown';
import {
  QUALIFICATION_APP_URL,
  toQualificationsApp
} from '../QualificationLinks';
import { defaultQualificationDetailQueryParams } from './QualificationDetailQueryParams';

export async function loadQualification(
  code: string,
  queryParams: ProductDetailQueryParams
) {
  return SimpleQualification.one(code, queryParams);
}

export default function QualificationDetail() {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useQueryParams<ProductDetailQueryParams>({
    location,
    defaultQueryParams: defaultQualificationDetailQueryParams(),
    debugName: 'QualificationDetail'
  });

  const { code = '' } = useParams<ProductDetailPathParams>();
  const { t } = useTranslation(['translation', 'qualification', 'import']);

  const state = useProductQuery<SimpleQualification>({
    productType: 'qualification',
    loadFunction: loadQualification,
    code,
    queryParams,
    filterChanged
  });

  const activeMainTab = useActiveMainTab();
  const subTabs = useSubTabs();
  const activeSubTab = getActiveSubTab(subTabs, queryParams.tab);

  function filterChanged(name: string, value: string | number) {
    navigate(
      toQualificationDetail({ code }, { ...queryParams, [name]: value }),
      {
        replace: true
      }
    );
  }

  async function importQualification(qualification: SimpleQualification) {
    try {
      await SimpleQualification.import(qualification);
      addSuccess({
        text: t('import:STARTED_MESSAGE')
      });
    } catch (error) {
      /* empty */
    }
  }

  return (
    <Loader
      state={state}
      isEmpty={(data) => data?.id === undefined}
      emptyContent={() => (
        <Navigate
          to={toQualificationsApp(
            toQueryParams(activeMainTab, queryParams.year)
          )}
          replace
        />
      )}
    >
      {(qualification) => (
        <AppFrame
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbItem>
                <Link
                  to={toQualificationsApp(
                    toQueryParams(activeMainTab, qualification.data.year.id)
                  )}
                >
                  {t('qualification:QUALIFICATIONS')}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{qualification.data.code}</BreadcrumbItem>
            </Breadcrumb>
          }
        >
          <Row className="justify-content-center">
            <Col
              md={12}
              lg={12}
              xs={12}
              className="details__content__main me-auto"
            >
              <Provider store={structureStore}>
                <ProductHeader
                  product={qualification}
                  tabId={activeMainTab?.id}
                />

                <Row style={{ minHeight: '46px' }}>
                  <Col
                    m={12}
                    md={12}
                    lg={12}
                    xs={12}
                  >
                    <ProductDetailYearDropdown
                      yearExternalId={queryParams.year}
                      productCode={qualification.data.code}
                      productYearsPromise={SimpleQualification.years}
                      onChange={filterChanged}
                    />
                    <WidgetButtons product={qualification}>
                      <AdminButton
                        label={t('import:BUTTON')}
                        onClick={() => importQualification(qualification)}
                      />
                      <PrintButton>
                        <ProductPrint
                          tabs={subTabs}
                          product={qualification}
                        />
                      </PrintButton>
                    </WidgetButtons>
                  </Col>
                </Row>

                <SubTabsNav
                  subTabs={subTabs}
                  activeTabName={activeSubTab?.name}
                  product={state.data}
                  toggle={filterChanged}
                />

                <TabContent activeTab={activeSubTab?.name}>
                  {subTabs.map((subTab) => (
                    <TabPane
                      tabId={subTab.name}
                      key={subTab.id}
                    >
                      <WidgetDetail
                        tabId={subTab.id}
                        product={qualification}
                        expanded={false}
                      />
                    </TabPane>
                  ))}
                </TabContent>
              </Provider>
            </Col>
          </Row>
        </AppFrame>
      )}
    </Loader>
  );
}

export const QUALIFICATION_DETAIL_URL = `${QUALIFICATION_APP_URL}/:code`;

function toQualificationDetail(
  pathParams: ProductDetailPathParams,
  queryParams: ProductDetailQueryParams
): Url {
  return urlBuilder({
    url: QUALIFICATION_DETAIL_URL,
    pathParams: pathParams,
    queryParams: queryParams
  });
}

export function qualificationDetailUrl({
  id,
  uid,
  code,
  year,
  mainTab
}: {
  mainTab?: string;
} & Qualification) {
  return toQualificationDetail(
    {
      code: code || id || uid
    },
    {
      year: year.id,
      mainTab
    }
  );
}
