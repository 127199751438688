import { type QueryClient } from '@tanstack/react-query';
import { router } from 'react-query-kit';
import { invalidateQueryCaches } from '../../../core/api/invalidateQueryCaches';
import {
  getRequest,
  buildBaseUrl,
  getPagedRequest,
  deleteRequest,
  saveRequest
} from '../../../core/api/requests';
import type Widget from '../types/Widget';
import { type WidgetFormData } from '../types/WidgetFormData';
import {
  type WidgetListQueryParams,
  type WidgetPagedQueryParams
} from '../types/WidgetQueryParams';

const baseUrl = buildBaseUrl('/widgets');

const widgetApi = router('widgets', {
  list: router.query({
    fetcher: (queryParams: WidgetListQueryParams) =>
      getRequest<Widget[]>(baseUrl, queryParams)
  }),

  page: router.query({
    fetcher: (queryParams: WidgetPagedQueryParams) =>
      getPagedRequest<Widget>(baseUrl, queryParams)
  }),

  remove: router.mutation({
    mutationFn: (data: WidgetFormData) => deleteRequest<Widget>(baseUrl, data)
  }),

  save: router.mutation({
    mutationFn: (data: WidgetFormData) => saveRequest<Widget>(baseUrl, data)
  })
});

const invalidateWidgetCaches = async (queryClient: QueryClient) => {
  await invalidateQueryCaches(queryClient, widgetApi);
};

export { widgetApi, invalidateWidgetCaches };
