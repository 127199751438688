import { get, makeResource, type Page } from '@42.nl/spring-connect';
import _ from 'lodash';
import { type QueryParams, search } from '../filters/Search';
import type Reference from '../references/Reference';
import { getReferenceById } from '../references/ReferenceService';
import type AcademicYear from './AcademicYear';
import type Group from './canonical/Group';
import type GroupGroup from './canonical/GroupGroup';
import type StudyGroup from './canonical/StudyGroup';
import { type GroupStudy } from './GroupStudy';
import { type Product, ProductTypeEnum } from './Product';
import { type Rule } from './Rule';

const baseUrl = '/api/group';

export default class SimpleGroup
  extends makeResource<SimpleGroup>(baseUrl)
  implements Product
{
  id!: number;
  data!: Group;
  productType!: string;

  static parents(product: Product): Promise<GroupStudy[]> {
    if (product.id === undefined) {
      return Promise.resolve([]);
    }

    return get(`${baseUrl}/${product.id}/parents`);
  }

  static years(code: string): Promise<AcademicYear[]> {
    return get(`${baseUrl}/${code}/years`);
  }

  static rules(product: Product): Promise<Rule[]> {
    return get(`${baseUrl}/${product.id}/rules`);
  }

  static async search(queryParams: QueryParams): Promise<Page<SimpleGroup>> {
    return search<SimpleGroup>(ProductTypeEnum.GROUP, baseUrl, queryParams);
  }

  static readonly GROUP_MODULE_QUERY_KEY = 'groupModules';
}

export function sortGroups(
  groups: GroupGroup[] | StudyGroup[] | undefined,
  types: Reference[]
): GroupGroup[] | StudyGroup[] {
  // Set default values of sorting properties so that nulls are sorted first
  const sortedGroups = _(groups)
    .map((group) => ({
      group,
      code: _.get(group, 'group.code', ''),
      abbreviation: _.get(group, 'group.abbreviation', ''),
      sequence: _.get(group, 'sequence', -1),
      typeSequence: _.get(
        getReferenceById(types, group.group?.type),
        'sequence',
        -1
      )
    }))
    .orderBy(['sequence', 'typeSequence', 'code', 'abbreviation'])
    .value();

  return sortedGroups.map((group) => group.group);
}
