import {
  useQuery,
  type UseQueryResult,
  type UseQueryOptions
} from '@tanstack/react-query';
import type Group from '../../../types/canonical/Group';
import type GroupModule from '../../../types/canonical/GroupModule';
import SimpleGroup from '../../../types/SimpleGroup';
import SimpleModule, { type ModuleRow } from '../../../types/SimpleModule';

type GroupModulesTypeProps = {
  group: Group;
  options?: Partial<UseQueryOptions<ModuleRow[], Error>>;
};

export default function useGroupModules({
  group,
  options
}: Readonly<GroupModulesTypeProps>): UseQueryResult<ModuleRow[], Error> {
  return useQuery<ModuleRow[], Error>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [SimpleGroup.GROUP_MODULE_QUERY_KEY, group.uid, group.year.id],
    queryFn: () => loadModuleRows(group),
    enabled: !!group.uid,
    ...options
  });
}

export async function loadModuleRows(group: Group): Promise<ModuleRow[]> {
  const page = await SimpleModule.page({
    group: group.uid,
    year: group.year.id,
    size: 999
  });

  return (group.modules || [])
    .map((structure) => buildRow(structure, page.content))
    .filter((row) => row.module);
}

function buildRow(structure: GroupModule, modules: SimpleModule[]): ModuleRow {
  return {
    structure,
    module: modules.find(
      (module) => module.data?.code === structure.module?.code
    )
  };
}
