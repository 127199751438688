import React from 'react';
import _, { isEmpty } from 'lodash';
import { displayName } from '../../../references/Reference';
import { useReferences } from '../../../references/useReferences';
import type Offering from '../../../types/canonical/Offering';
import { getValue } from '../../../types/canonical/Value';
import { type WidgetType } from '../../types/WidgetType';
import type WidgetField from '../../WidgetField/WidgetField';
import { FieldSection } from './FieldSection';

type Props = {
  field: WidgetField;
  type: WidgetType;
  offerings: Offering[] | undefined;
  year: string;
  hideHeader?: boolean;
  isTitle: boolean;
};

export function PeriodsValueItem({
  field,
  type,
  offerings,
  year,
  hideHeader,
  isTitle
}: Readonly<Props>) {
  const { references } = useReferences(field.referenceType, year);

  const periods = _(offerings)
    .map((offering) => {
      const value = getValue(field.name, offering.values);

      return {
        name: offering.period?.code,
        value: displayName(value, references, value)
      };
    })
    .orderBy('name')
    .value();

  const values = periods
    .map((period) => period.value)
    .filter((value) => !isEmpty(value));

  return (
    <FieldSection
      field={field}
      type={type}
      isEmpty={isEmpty(values)}
      hideHeader={hideHeader}
      isTitle={isTitle}
    >
      {() => (
        <dl className="horizontal">
          {periods?.map((period, index) => (
            <React.Fragment key={`period-value-${index}`}>
              <dt>{period.name}</dt>
              <dd>{period.value || '-'}</dd>
            </React.Fragment>
          ))}
        </dl>
      )}
    </FieldSection>
  );
}
