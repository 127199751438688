import { type LocalizedText } from '../../i18n/LocalizedText';
import type Additional from './Additional';
import type Appraisal from './Appraisal';
import type Capacity from './Capacity';
import type Credits from './Credits';
import type Description from './Description';
import type License from './License';
import type Link from './Link';
import type MethodSchema from './MethodSchema';
import type Objective from './Objective';
import type Offering from './Offering';
import type Reference from './Reference';
import type Relation from './Relation';
import type Subject from './Subject';
import type Value from './Value';

export default class Data {
  id?: string;
  uid!: string;
  code?: string;
  type?: string;
  key?: number;
  names?: LocalizedText[];
  year!: Reference;
  faculty?: Reference;
  organisation?: Reference;
  additional?: Additional;
  capacity?: Capacity;
  credits?: Credits;
  descriptions?: Description[];
  relations?: Relation[];
  objectives?: Objective[];
  offerings?: Offering[];
  licenses?: License[];
  links?: Link[];
  subjects?: Subject[];
  assessments?: Appraisal[];
  methods?: MethodSchema[];
  values?: Value[];
}

export function getDefaultCode(data: Data) {
  return data.code || data.id || data.uid;
}
