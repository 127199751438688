import { useQuery } from '@tanstack/react-query';
import { isEmpty, sortBy } from 'lodash';
import { Link as ReactLink } from 'react-router-dom';
import Loader from '../../../components/Loader/Loader';
import { getText } from '../../../i18n/LocalizedText';
import Link from '../../../types/Link';
import { type Product, getProductUrl } from '../../../types/Product';
import { type WidgetType } from '../../types/WidgetType';
import type WidgetField from '../../WidgetField/WidgetField';
import { FieldSection } from './FieldSection';

type Props = {
  field: WidgetField;
  type: WidgetType;
  product: Product;
  hideHeader?: boolean;
  isTitle: boolean;
};

async function getTargets(product: Product, targetType: string | undefined) {
  if (!targetType) {
    return Promise.resolve([]);
  }

  const targets = await Link.getRelated(product, targetType);
  return sortBy(targets, (target) => getText(target.data.names));
}

export function LinksItem({
  field,
  type,
  product,
  hideHeader,
  isTitle
}: Readonly<Props>) {
  const state = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      Link.QUERY_KEY,
      field.referenceType,
      product.data.id,
      product.data.year.id
    ],
    queryFn: () => getTargets(product, field.referenceType)
  });

  return (
    <Loader state={state}>
      {(targets) => (
        <FieldSection
          field={field}
          type={type}
          isEmpty={isEmpty(targets)}
          hideHeader={hideHeader}
          isTitle={isTitle}
          viewClasses="d-flex flex-column text-break"
        >
          {() => (
            <>
              {targets.map((target) => (
                <ReactLink
                  key={target.id}
                  to={getProductUrl(target)}
                >
                  {getText(target.data.names)}
                </ReactLink>
              ))}
            </>
          )}
        </FieldSection>
      )}
    </Loader>
  );
}
