import { type Product } from '../../../types/Product';
import type Widget from '../../types/Widget';
import { getVisibleSortedWidgetFields } from '../../utils/WidgetUtils';
import { WidgetItem } from '../../WidgetItem/WidgetItem';

type Props = {
  widget: Widget;
  product: Product;
  expanded: boolean;
  expandable: boolean;
};

export function ContainerWidget({
  widget,
  product,
  expanded,
  expandable
}: Readonly<Props>) {
  return (
    <>
      {getVisibleSortedWidgetFields(widget).map((field) => (
        <WidgetItem
          key={`field-${field.id}`}
          field={field}
          type={widget.type}
          hideHeader={widget.hideHeader}
          product={product}
          expanded={expanded}
          expandable={expandable}
          isTitle={false}
        />
      ))}
    </>
  );
}
